<template>
  <div id="container">
    <h1>OpenStreepMap Demo</h1>
    <div class="search">
      <label for="search-box">Search: </label>
      <input id="search-box" type="text" v-model="searchString" />
      <button class="searchBtn" @click="doSearch">Search</button>
      <button class="searchBtn" @click="doGo">Go</button>
      <button class="searchBtn" @click="centerMap">Origin</button>
      <button class="searchBtn" @click="clear">Clear</button>
    </div>

    <div class="map-section">
      <!-- Map container -->
      <div
        id="mapContainer"
        ref="theMap"
        @contextmenu.prevent.stop="handleContextMenu($event)"
        @click="handleMapClick"
      >
        <context-menu
          :display="showContextMenu"
          :choices="choices"
          ref="menu"
          class="context-menu"
        >
        </context-menu>
      </div>
    </div>
  </div>
</template>

<script>
// Leaflet imports
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Service import
import apiService from "../service/ApiService";

import ContextMenu from "../components/ContextMenu";

export default {
  name: "map-demo",
  components: {
    ContextMenu,
  },
  data() {
    return {
      searchString: "",
      showContextMenu: false,
      map: {},
      mapCenter: [39.9552836, -75.182144],
      startSearch: 'Duane Morris Plaza Philadelphia',
      zoom: 13,
      // create Leaflet icon from image - need require to force image to be available
      markerIcon: L.icon({
        iconUrl: require("../assets/marker-blue.png"),
        iconSize: [30, 30],
      }),
      choices: ["Add Pin", "Cancel"],
      markerChoices: ["Remove Pin", "Cancel"],
      savedEvent: "",
      savedMarkerEvent: "",
      goPoint: '',
    };
  },
  mounted() {
    // wait for component to be mounted, then create t
    this.setupLeafletMap();
    this.search(this.startSearch, 5);
  },
  computed: {
    choice() {
      return this.$store.state.contextSelection;
    },
  },
  methods: {
    setupLeafletMap: function () {
      this.map = L.map("mapContainer", {
        center: this.mapCenter,
        zoom: this.zoom,
      });

      // Create the map using  OpenStreetMap - MUST include attribution to use OpenStreeyMap
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution:
          '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
      }).addTo(this.map);

      // show the scale bar on the lower left corner
      L.control.scale({ imperial: true, metric: true }).addTo(this.map);
    },
    doSearch() {
      this.search(this.searchString, 20);
      this.searchString = "";
    },
    doGo() {
      this.go(this.searchString, 20);
      this.searchString = "";
    },
    go(searchString, limit) {
      apiService.addressSearch(searchString, limit).then((resp) => {

        if (resp.data.length > 0) {
        this.addAddressMarker(resp.data);
        if (this.goPoint != '') {
        this.map.setView([this.goPoint.lat, this.goPoint.lng], this.zoom);
        }
        }
      });
    },
    search(searchString, limit) {
      apiService.addressSearch(searchString, limit).then((resp) => {
        this.addMarker(resp.data);
      });
    },
    addAddressMarker(searchResult) {
      this.goPoint = '';
      if (searchResult.length > 0) {
        // iterate through all results
        for (let i = 0; i < searchResult.length; i++) {
          const place = searchResult[i];
          if (place.class == 'place') {
            // extract coordinates from data and create coordinates object
            const coords = { lng: place.lon, lat: place.lat };
            // pass coordinates and text for marker
            this.createMarker(coords, place.display_name);
            this.goPoint = coords;
          }
        }
      }
    },
    addMarker(searchResult) {
      if (searchResult.length > 0) {
        // iterate through all results
        for (let i = 0; i < searchResult.length; i++) {
          const place = searchResult[i];
          // this is just to filter out non building locations (just added this for Duane Morris Plaza - may be a better way)
          if (place.type != "pedestrian" ) {
            // extract coordinates from data and create coordinates object
            const coords = { lng: place.lon, lat: place.lat };
            // pass coordinates and text for marker
            this.createMarker(coords, place.display_name);
          }
        }
      }
    },
    createMarker(coords, displayText) {
      if (!this.markerExists(coords)) {
      // create a Leaflet marker from coords and displayText using the marker icon
      const marker = L.marker(
        { lon: coords.lng, lat: coords.lat },
        { icon: this.markerIcon }
      )
        .bindPopup(displayText) // bind text to marker popup
        // these are for showing popup on hover in addition to click (remove if not wanted)
        .on("contextmenu", (event) => this.handleMarkerClick(event))
        .on("mouseover", function () {
          this.openPopup();
        })
        .on("mouseout", function () {
          this.closePopup();
        })
        .addTo(this.map); // add marker to map
      this.$store.commit("ADD_MARKER", marker);
      }
    },
    getCoordsFromClick(event) {
      return this.map.mouseEventToLatLng(event);
    },
    createMarkerFromCoords(coords) {
      apiService.reverseLookup(coords).then((resp) => {
        const info = resp.data.address;
        const displayText = this.getInfoFromReverseLookupData(info);
        this.createMarker(coords, displayText);
      });
    },
    getInfoFromReverseLookupData(info) {
      const text = `${info.house_number ? info.house_number : ""} ${
        info.road
      }, ${info.city} ${info.state} ${info.postcode}, ${info.country}`;
      return text;
    },
    // recenter, reset zoom factor
    centerMap() {
      this.map.setView(this.mapCenter, this.zoom);
    },
    handleContextMenu(event) {
      // make sure this came from map
      if (event.target == event.currentTarget) {
        this.savedEvent = event;
        this.$refs.menu.open(event, this.map.mouseEventToContainerPoint(event), this.choices);
      }
    },
    handleMapClick() {
      this.$refs.menu.close();
      this.savedEvent = {};
    },
    handleMarkerClick(event) {
        this.savedMarkerEvent = event;
        this.$refs.menu.open(event, this.map.mouseEventToContainerPoint(event.originalEvent), this.markerChoices);
    },
    removeMarker() {
      const id = this.savedMarkerEvent.target._leaflet_id;
      const marker = this.$store.state.markers.find(
        (curMarker) => curMarker._leaflet_id == id
      );
      marker.closePopup().unbindPopup();
      marker.remove();
      this.$store.commit('REMOVE_MARKER', id);
      this.savedMarkerEvent = "";
    },
    clear() {
      this.$store.state.markers.forEach( marker => {
        marker.closePopup().unbindPopup();
        marker.remove();
      });
      this.$store.commit('REMOVE_ALL_MARKERS');
      this.centerMap();
      this.search(this.startSearch, 5);
    },
    markerExists(coords) {
      let exists = false;
      this.$store.state.markers.forEach( marker => {
        const point = marker._latlng;
        if (coords.lng == point.lng && coords.lat == point.lat) {
          exists = true;
        }
      })
      return exists;
    }
  },
  watch: {
    // whenever question changes, this function will run
    choice(newChoice, oldChoice) {
      if (newChoice != oldChoice) {
        if (newChoice === "Add Pin") {
          if (this.savedEvent != "") {
            const coords = this.getCoordsFromClick(this.savedEvent);
            this.createMarkerFromCoords(coords);
          }
        } else if (newChoice === "Remove Pin" && this.savedMarkerEvent != "") {
          this.removeMarker();
        }
      }
    },
  },
};
</script>

<style scoped>
#mapContainer {
  width: 90vw;
  height: 100vh;
  margin: 10px auto;

  border: 5px solid #00adee;
}

.search {
  font-size: 20px;
  color: #ffffff;
  background-color: #00adee;
  padding: 20px 5px;
  width: 90vw;
  margin: 0 auto;
}

.map-section {
  position: relative;
  margin-top: 20px;
}
.searchBtn {
  color: #ffffff;
  background-color: #00adee;
  border: 1px solid #ffffff;
  margin-left: 10px;
}

.searchBtn:hover {
  color: #00adee;
  background-color: #ffffff;
}

.context-menu {
  position: relative;
  width: 100px;
}
</style>