<template>
  <div
    class="context-menu"
    ref="context"
    tabindex="0"
    v-show="show"
    :style="style"
    @contextmenu.prevent.stop="close()"
  >
    <slot>
      <div
        v-for="(choice, index) in choices"
        :key="index"
        class="choice"
        @click="handleChoice(choice)"
      >
        {{ choice }}
      </div>
    </slot>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "CmpContextMenu",
  props: {
    display: Boolean, // prop detect if we should show context menu
  },
  data() {
    return {
      left: 0, // left position
      top: 0, // top position
      show: false, // affect display of context menu,
      choices: []
    };
  },
  computed: {
    // get position of context menu
    style() {
      return {
        top: this.top + "px",
        left: this.left + "px",
      };
    },
  },
  methods: {
    // closes context menu
    close() {
      this.show = false;
      this.left = 0;
      this.top = 0;

      this.$store.commit("UPDATE_CONTEXT_MENU_VISIBLE", false);
    },
    open(evt, boundingBox, choices) {
      // updates position of context menu
      this.left = boundingBox.x;
      this.top = boundingBox.y;

      // set choices
      this.choices = choices;
      // make element focused
      // @ts-ignore
      Vue.nextTick(() => this.$el.focus());
      this.show = true;

      this.$store.commit("UPDATE_CONTEXT_MENU_CHOICE", "");
      this.$store.commit("UPDATE_CONTEXT_MENU_VISIBLE", true);
    },
    handleChoice(choice) {
      this.$store.commit("UPDATE_CONTEXT_MENU_CHOICE", choice);
    },
  },
};
</script>

<style>
.context-menu {
  position: fixed;
  background: white;
  z-index: 999;
  outline: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  text-align: left;
}

.choice {
  padding: 5px 5px;
}

.choice:hover {
  color: #ffffff;
  background-color: #00adee;
}
</style>