<template>
  <div id="app">
    <img alt="Tech Elevator" src="./assets/logo.png" class="logo">
    <map-demo />
  </div>
</template>

<script>
import MapDemo from './components/MapDemo.vue'



export default {
  name: 'App',
  components: {
    MapDemo
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #00ADEE;
  margin-top: 20px;
}

.logo {
  width: 100px;
  height: auto;
}


/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #00ADEE;
  margin-top: 60px;
  
  height: 100vh;
} */
/* 
body {
  background-color: lightgray;
} */
</style>
