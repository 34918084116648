import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    savedInfo: {},
    markers: [],
    contextMenuVisible: false,
    contextSelection: '',
    contextChoices: []
  },
  mutations: {
    SAVE_INFO(state, info) {
      state.savedInfo = info;
    },
    ADD_MARKER(state, newMarker) {
      state.markers.push(newMarker);
    },
    REMOVE_MARKER(state, id) {
      state.markers = state.markers.filter(
      (curMarker) => curMarker._leaflet_id != id
      );
    },
    REMOVE_ALL_MARKERS(state) {
      state.markers = [];
    },
    UPDATE_CONTEXT_MENU_VISIBLE(state, isVisible) {
      state.contextMenuVisible = isVisible;
    },
    UPDATE_CONTEXT_MENU_CHOICE(state, choice) {
      state.contextSelection = choice;
    },
    UPDATE_CONTEXT_CHOICES(statw, choices) {
      this.contextChoices = choices;
    }
  },
  actions: {
  },
  modules: {
  }
})
