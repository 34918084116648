import axios from 'axios';

export default {

    addressSearch(searchString, limit) {
        return axios.get(`https://nominatim.openstreetmap.org/?addressdetails=1&q=${searchString}&format=json&limit=${limit}`);
    },
    reverseLookup(coords) {
        return axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${coords.lat}&lon=${coords.lng}&format=json`)
    }

}